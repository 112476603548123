// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyAZln10eb9aYenIt5UqdsJdho1lmIpjJA8",
    authDomain: "maaraaz-1444h.firebaseapp.com",
    projectId: "maaraaz-1444h",
    storageBucket: "maaraaz-1444h.appspot.com",
    messagingSenderId: "621128815567",
    appId: "1:621128815567:web:e54c50e3c73cba86d5f8ba",
    measurementId: "G-N4H4ZLVDFQ"
  },
  blrjmtLogin:{
    key:"AqaMoula@SyednaMufaddalSaifuddin",
    vec:"7865253123456789",
    AppRef:"Maraaz1444H"
  },
  emailUrl: 'https://zest-valuable-closet.glitch.me',
  whatsappUrl:'https://wawarp.herokuapp.com',
  paymentUrl:'https://pay.blrjmt.com/AppHandler?AppRef=Maraaz1444H&Info='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
